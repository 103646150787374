import "../../styles/components/tables.css";
import { CustomButton } from "../../components/customButton";
import { useNavigate } from "react-router-dom";
import { NetworkStatus, useQuery } from "@apollo/client";
import { GET_COMPANIES } from "../../api/graphql/companies";
import { FullContainerSpinner } from "../../components/fullContainerSpinner";
import { ICompany } from "../../@types/graphql";
import { memo } from "react";
import {
  ColumnDef,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";

const columnHelper = createColumnHelper<ICompany>();

const companiesColumns: ColumnDef<ICompany>[] = [
  columnHelper.accessor("id", {
    id: "id",
    header: "ID",
    size: 20,
  }),
  columnHelper.accessor("name", {
    id: "name",
    header: "Название",
  }),
];

const CompaniesTable = memo<{ companies: ICompany[] }>(({ companies }) => {
  console.log(companies);
  const table = useReactTable({
    data: companies,
    columns: companiesColumns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="table-default-wrapper">
      <div className="table-default">
        <table className="table-default skus-table">
          <thead className="table-default-header">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    style={{ width: header.getSize() }}
                    colSpan={header.colSpan}
                  >
                    <span className="table-default-sorting-item">
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr className="skus-table-row" key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id} className={cell.column.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
});
CompaniesTable.displayName = "CompaniesTable";

export const CompaniesPage = () => {
  const navigate = useNavigate();
  const { data, networkStatus } = useQuery<{ companies: ICompany[] }>(
    GET_COMPANIES,
    {
      fetchPolicy: "cache-and-network",
    },
  );

  const companies = data?.companies || [];

  return (
    <div className="default-page">
      <h2 className="mainTitle flex items-center gap-24 justify-between">
        Компании
        <CustomButton onClick={() => navigate("/companies/create")}>
          Добавить компанию
        </CustomButton>
      </h2>
      {[NetworkStatus.loading, NetworkStatus.setVariables].includes(
        networkStatus as number,
      ) && <FullContainerSpinner />}
      <CompaniesTable companies={companies} />
    </div>
  );
};
