import { gql } from "@apollo/client";

export const GET_COMPANIES = gql`
  query companies {
    companies {
      id
      name
    }
  }
`;

export const CREATE_COMPANY = gql`
  mutation addCompany($name: String) {
    addCompany(name: $name) {
      id
      name
    }
  }
`;
